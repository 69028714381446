/* eslint-env jquery */
/**
 * File for Animate Functionality
 *
 * @author  Twilo Creative
 */

jQuery(document).ready(function($) {
    const animateConfig = {
        windowProportion: 6/7
    }

    function animateOnScroll() {
        var scrollTop = '';
        var scrollHeight = $(window).scrollTop() + ($(window).height() * animateConfig.windowProportion);

        $('.animate-container').each(function(i, el) {
            var $el = $(el);

            if ( $el.hasClass('animate-intro') ) {
                $el.addClass('reveal');
                return;
            }

            if ($el.is(':visible')) {
                if (!isNaN(parseInt($el.css('top')))) {
                    scrollTop = $el.offset().top - parseInt($el.css('top'));
                } else {
                    scrollTop = $el.offset().top;
                }

                if (scrollTop < scrollHeight) {
                    $el.addClass('reveal');
                } else {
                    if ( !$el.hasClass('animate-once') ) {
                        $el.removeClass('reveal');
                    }
                }
            }
        });
    }

    $(window).on('scroll', animateOnScroll);
    $(document).on('tab-changed', animateOnScroll);
    animateOnScroll();
});
