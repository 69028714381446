/**
 * Twilo JS File
 * @author Twilo Creative
 */

// Modules
import * as bootstrap from "./modules/bootstrap.bundle.js";
window.bootstrap = bootstrap;

// Comment out to disable

// Core
import "./core/animation.js";

// Site-specific JS
import "./custom.js";