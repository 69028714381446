/* eslint-env jquery */
/* eslint-disable no-console */
// Twilo Global Config
jQuery(document).ready(function($) {
	console.info('Twilo OC4 v1.0');
	$('body').addClass('ready');

	// Player
	$('.btn-listen').click( function() {
		let self = $(this);
		let before = '<i class="fa fa-headphones"></i>';
		let after = '<i class="fa fa-times"></i>';
		if ( self.html() == before ) {
			$(self.data('listen')).show();
			$(self.data('listen'))[0].play();
			self.parent().next().find('p').hide();
			self.html(after)
		} else {
			$(self.data('listen')).hide();
			$(self.data('listen'))[0].pause();
			self.parent().next().find('p').show();
			self.html(before)
		}
	});
	
});